/* eslint-disable */
import React, { useState } from 'react';
import { Button, message, Space, Spin, Table } from 'antd';

import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import showDeleteConfirm from '../../components/Confirmation-modal';
import { useManagementStore, useUserState } from '../../store';
import axios from '../../utilities/axios';

import './styles.scss';

export default function FileManagementPage() {

  const { getUser } = useUserState();
  const { company } = useManagementStore((state) => state);
  const user = getUser();
  const queryClient = useQueryClient();
  const [processing, setProcessing] = useState(false);

  const fetchLiveTradeLogs = () => axios.get('crud/logs/livetradeaccounts', { 
    headers: { token: user?.token },
    params: { company: company }
  }).then(({ data }) => data);

  const { isLoading, data: logData } = useQuery(['live-trade-logs', company], () => fetchLiveTradeLogs());

  const logDelete = (isConfirmed, fileName) => {

    if (isConfirmed) {

      setProcessing(true);

      axios.delete(`crud/logs/liveTrade/${fileName}`, {
        headers: {
          token: user?.token
        },
        timeout: 100000
      }).then(() => {

        message.success('File deleted');

        queryClient.invalidateQueries(['live-trade-logs']);

      }).catch((error) => {

        message.error(error.response.data.message || 'Oops! Something Went Wrong. Please try again.');

      }).finally(() => {

        setProcessing(false);

      });

    }

  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'originalFileName',
      key: 'originalFileName',
      render: (_, record) => (
        <span>
          {record.originalFileName && record.originalFileName.length > 0 ? record.originalFileName : record.fileName}
        </span>
      )
    },
    {
      title: 'File Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate'
    },
    {
      title: 'Uploaded by',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      render: (_, record) => (
        <span>
          {record.uploadedBy && record.uploadedBy.length > 0 ? record.uploadedBy : '---'}
        </span>
      )
    },
    {
      title: 'Number of Trades',
      dataIndex: 'logs',
      key: 'logs',
      render: (_, record) => (<span>{record.totalTradeCount}</span>)
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Button
          size='small'
          type='text'
          onClick={() => showDeleteConfirm(
            (isConfirmed) => logDelete(isConfirmed, record.fileName),
            record.label,
            `Are you sure to delete "${record.originalFileName && record.originalFileName.length > 0 ? record.originalFileName : record.fileName}"?`
          )}
          icon={<DeleteOutlined />}
        >
          Delete File
        </Button>
      )
    }
  ];

  return (
    <Spin className='upload-spin' indicator={<LoadingOutlined />} spinning={processing} tip='Processing file deletion...'>
      <Space direction='vertical' align='start' style={{ width: '100%' }}>
        {!isLoading && (
          <Table
            columns={columns}
            dataSource={logData}
            pagination={false}
            rowKey='key'
            style={{ width: '100%' }}
          />
        )}
      </Space>
    </Spin>
  );

}
