import React from 'react';
import { Button, Select, Space, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router';

import { useQuery } from '@tanstack/react-query';

import { useAccountStore, useManagementStore, useUserState } from '../../../../store';
import axios from '../../../../utilities/axios';
import usePageTitle from '../../../../utilities/hooks/usePageTitle';

import './styles.scss';

const ContentHeader = () => {

  const navigate = useNavigate();
  const { allAccounts, setSelectedAccount } = useAccountStore((state) => state);
  const { viewAs, setViewAs, allUsers, setAllUsers, company } = useManagementStore((state) => state);
  const { getUser } = useUserState();
  const user = getUser();
  const { pathname } = useLocation();
  const isDashboard = pathname === '/';
  const pageTitle = usePageTitle();

  const fetchUsers = () => axios.get('crud/users', { headers: { token: user?.token } }).then(({ data }) => data);

  useQuery(['users', company], () => fetchUsers().then(async (users) => {

    try {

      if (users.length > 0) {

        let userSelectionFormat = [];

        if (company === 'Bear Bull Traders') {

          userSelectionFormat = users?.map((u) => {

            if (u.ptt_id && u.ptt_id !== '') {

              return {
                value: u.id,
                label: u.ptt_id,
                token: u.token
              };

            }
            return null;

          }).filter(Boolean) || [];

        } else if (company === 'Peak Capital Trading') {

          userSelectionFormat = users?.map((u) => {

            if (u.pct_id && u.pct_id !== '') {

              return {
                value: u.id,
                label: u.pct_id,
                token: u.token
              };

            }
            return null;

          }).filter(Boolean) || [];

        }

        // const userSelectionFormat = users?.map((u) => {

        //   if (u.role !== 'admin') {

        //     return {
        //       value: u.id,
        //       label: u.pct_id && u.pct_id !== '' ? u.pct_id : u.name,
        //       token: u.token
        //     };

        //   }
        //   return null;

        // }).filter(Boolean) || [];

        // const currentAdmin = users.find((x) => x.id === user.id);

        // userSelectionFormat.unshift({
        //   value: user?.id,
        //   label: `${currentAdmin && currentAdmin.pct_id && currentAdmin.pct_id !== '' ? currentAdmin.pct_id : user?.name} (Me)`,
        //   token: user?.token
        // });

        const sortedUsers = userSelectionFormat
          .filter((u) => u && typeof u === 'object' && 'label' in u) // Ensure valid entries
          .sort((a, b) => {

            const aIncludes = a.label.includes('TRPCT') || a.label.includes('TRPTT');
            const bIncludes = b.label.includes('TRPCT') || b.label.includes('TRPTT');

            if (aIncludes && !bIncludes) return -1; // `a` goes before `b`
            if (!aIncludes && bIncludes) return 1; // `b` goes before `a`

            // If both include or both do not include, sort alphabetically
            return a.label.localeCompare(b.label);

          });

        setAllUsers(sortedUsers);

        if (company === 'Bear Bull Traders' || company === 'Peak Capital Trading') {

          setViewAs({ token: sortedUsers[0]?.token, id: sortedUsers[0]?.value }, { token: user?.token, id: user?.id });

        }

      } else {

        setAllUsers([]);

      }
      return users;

    } catch (error) {

      return [];

    }

  }), { enabled: user.token !== undefined && user.role === 'admin' });

  const onChange = (newId) => {

    const newToken = allUsers.find((u) => u.value === newId)?.token;
    setViewAs({ token: newToken, id: newId }, { token: user?.token, id: user?.id });
    if (!isDashboard) navigate('/');

  };

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const redirectToAddTrade = () => {

    if (allAccounts.filter((x) => x.label !== 'Live Trade')?.length > 0) {

      setSelectedAccount(allAccounts.filter((x) => x.label !== 'Live Trade')[0]);

    }
    navigate('/add-trade');

  };

  return (
      <div className='content-header' style={{
        padding: '8px 16px',
        background: '#FFFFFF',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <h1 className='content-header-title'>{pageTitle}</h1>
              {user.role === 'admin' && company !== 'Personal' && allUsers && (
              <Space size={6}>
                  <Select
                      showSearch
                      placeholder='Select a user'
                      maxTagCount={1}
                      value={viewAs?.newUser?.id.length && allUsers.find((u) => u.value === viewAs?.newUser?.id) ? viewAs.newUser.id : allUsers[0]?.value}
                      onChange={onChange}
                      filterOption={filterOption}
                      options={allUsers}
            />
              </Space>
              )}
          </div>
          {(allAccounts.filter((x) => x.label !== 'Live Trade')?.length > 0) && (
              <Tooltip title='Import Trade Logs'>
                  <Button
                      type='primary'
                      icon={<span className='tt-plus' style={{ fontSize: 12 }} />}
                      onClick={redirectToAddTrade}
                  >
                      Import trades
                  </Button>
              </Tooltip>
          )}
      </div>
  );

};

export default ContentHeader;
