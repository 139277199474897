import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';

import { useAccountStore, useManagementStore, useSettingStore, useUserState } from '../../../../store';

import './styles.scss';

const SubHeader = () => {

  const { allAccounts, changeAccount } = useAccountStore((state) => state);
  const { getUser } = useUserState();
  const { filter, changeFilter } = useSettingStore((state) => state);
  const [userAccounts, setUserAccounts] = useState([]);
  const onChangeAccounts = (checkedValues) => changeFilter('accounts', [checkedValues.key]);
  const { viewAs, company } = useManagementStore((state) => state);
  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);
  const user = getUser();

  // Checking all accounts in case it's first access and store doesn't contain any filter changes
  useEffect(() => {

    if (allAccounts.length === 0) {

      onChangeAccounts([]); // Make sure filter is empty if no accounts

    }

    // else if (Object.keys(filter.accounts).length === 0 && allAccounts.length > 0) {

    //   const liveTradeAccount = allAccounts?.filter((account) => account.liveAccount === true);
    //   if (isViewAsActive === false) {

    //     onChangeAccounts([allAccounts[0].key]);

    //   } else if (liveTradeAccount.length > 0) {

    //     onChangeAccounts([liveTradeAccount[0].key]);

    //   }

    //   onChangeAccounts([]);

    // }

    if (allAccounts.length > 0) {

      const accounts = allAccounts?.filter((account) => {

        if (isViewAsActive === false || (isViewAsActive === true && account.liveAccount === true)) {

          if (company === 'Bear Bull Traders') {

            return account.ptt_account && account.ptt_account !== '';

          }
          if (company === 'Peak Capital Trading') {

            return account.pct_account && account.pct_account !== '';

          }
          if (company === 'Personal') {

            return account.liveAccount === false;

          }
          return false;

        }
        return false;

      }).filter((account) => {

        if (user.role === 'admin') {

          const accountCopy = account;

          if (company === 'Bear Bull Traders' && account.liveAccount === true && account.ptt_account && account.ptt_account !== '') {

            accountCopy.label = 'BBT Live Trade';
            return accountCopy;

          } if (company === 'Peak Capital Trading' && account.liveAccount === true && account.pct_account && account.ptt_account !== '') {

            accountCopy.label = 'PCT Live Trade';
            return accountCopy;

          } if (company === 'Personal' && account.liveAccount === false) {

            return accountCopy;

          }

        } else {

          return account;

        }

        return null;

      });

      if (accounts.length > 0) {

        changeFilter('accounts', [accounts[0].key]);
        changeAccount(accounts[0]);

      }
      setUserAccounts(accounts);

    }

    // eslint-disable-next-line padded-blocks
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccounts, company]);
  const accountsOptions = userAccounts.map((i) => ({
    label: i.label,
    value: i.key,
    liveAccount: i.liveAccount
  }));

  const hasLiveAccounts = accountsOptions.some((acc) => acc.liveAccount);

  if (hasLiveAccounts) {

    accountsOptions.sort((a, b) => {

      if (!a.liveAccount) {

        return -1;

      } if (b.liveAccount) {

        return 1;

      }
      return 0;

    });

  }

  return (
    accountsOptions.length > 0 ?
        <Menu mode='horizontal' className='subheader-container' onSelect={onChangeAccounts} selectedKeys={filter.accounts} defaultSelectedKeys={[accountsOptions[0].value]}>
            {accountsOptions.map((acc) => (
                <Menu.Item key={acc.value}>
                    {acc.label}
                </Menu.Item>
            ))}
        </Menu>
      :
        <Menu mode='horizontal' className='subheader-container'>
            <Menu.Item disabled />
        </Menu>
  );

};

export default SubHeader;
