import React from 'react';
import { Image, Space } from 'antd';

import LogoImg from '../../../../resources/images/Logo.svg';

const Logo = () => (
    <Space direction='horizontal' className='logo-container'>
        <Image
            width={'100%'}
            src={LogoImg}
            preview={false}
            style={{ paddingLeft: '0.75rem' }}
        />
    </Space>
);
export default Logo;
