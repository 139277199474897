import React from 'react';
import { Button, Form, Input, message, Popconfirm, Row, Select, Space, Table, Tooltip, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router';

import { ClearOutlined, DeleteOutlined, FileSearchOutlined, WarningOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import { brokers } from '../../resources/static/select-input-data';
import { useAccountStore, useManagementStore, useSettingStore, useUserState } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT, LOGS } from '../../utilities/functions';

const { Title, Text } = Typography; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default function AccountsPage() {

  // const autoDetectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';
  const { allAccounts, setAllAccounts, setSelectedAccount } = useAccountStore((state) => state);
  const { filter, changeFilter } = useSettingStore((state) => state);
  // const [timezoneValue, setTimezoneValue] = useState(autoDetectedTimezone);
  const navigate = useNavigate();
  const { getUser } = useUserState();
  const user = getUser();
  const { viewAs } = useManagementStore((state) => state);
  const [form] = Form.useForm();

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  const downloadFile = (fileName) => axios.get(`upload/download-log?filename=${fileName}`).then((response) => {

    const tempLink = document.createElement('a');
    tempLink.href = response.data;
    tempLink.setAttribute('download', `${fileName}`);
    tempLink.click();

  });

  const fetchAccounts = () => axios.get('crud/account', {
    headers: { token: isViewAsActive ? viewAs.newUser.token : user?.token }
  }).then(({ data }) => data.map((a) => FORMAT.ACCOUNTS_BEAUTIFY(a)));

  const { refetch } = useQuery(['accounts', viewAs], () => fetchAccounts().then(async (accounts) => {

    const results = await LOGS.processLogs(accounts, setAllAccounts);

    return results;

  }), { enabled: user.token !== undefined });

  const deleteAccountByID = (id) => axios.delete(`crud/account/${id}`).then(() => {

    // Removing deleted account from filter
    const newFilter = filter.accounts.filter((itmInner) => itmInner !== id);
    changeFilter('accounts', newFilter);

    refetch();

  })
    .then(() => message.success('Account Deleted.'))
    .catch(() => {

      message.error('Something went wrong, please try again.');

    });

  const deleteAccountLogsByID = (id) => axios.delete(`crud/account/${id}/logs/all`)
    .then(() => refetch())
    .then(() => message.success('All Trades Removed.'))
    .catch(() => {

      message.error('Something went wrong, please try again.');

    });

  const deleteLogByID = (id, accountID, fileName, method) => axios.delete(`crud/logs/${id}`, { data: { key: fileName, method, accountID } })
    .then(() => refetch())
    .then(() => message.success('Log Deleted.'))
    .catch(() => {

      message.error('Something went wrong, please try again.');

    });

  const createAccount = (values) => {

    // Count manual accounts (non-live accounts)
    const manualAccountCount = allAccounts.filter((account) => !account.liveAccount).length;

    if (manualAccountCount >= 2) {

      message.error('Only two personal accounts allowed.');

    } else {

      axios.post('crud/account', values, { headers: { token: user?.token } }).then(() => {

        form.resetFields();
        return refetch();

      });

    }

  };

  // const { data: logs = [] } = useQuery(['logs', allAccounts], () => fetchLogs(allAccounts.map((account) => account._id)).then((data) => console.log(data)));

  // const onChangeTimezone = (value) => setTimezoneValue(value);

  const onFinish = (values) => {

    const val = values;

    val.brokerTimezone = 'America/New_York'; // Bug is not using correct value automatic

    val.profitCalculationMethod = 'average';

    if (user?.token) {

      createAccount(val).then(() => {

        form.resetFields();
        message.success('Account Created.');

      });

    }

  };
  const onFinishFailed = () => message.error('Failed.');

  const confirmDeleteAccount = (e) => {

    deleteAccountByID(e.key);

  };

  const confirmDeleteTrades = (e) => {

    deleteAccountLogsByID(e.key);

  };

  const redirectToAddTrade = (e) => {

    setSelectedAccount(e);
    navigate('/add-trade');

  };

  const confirmDeleteLog = (e) => {

    deleteLogByID(e._id, e.accountRef, e.fileName, e.profitCalculationMethod);

  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: 'Broker',
      dataIndex: 'brokerLabel',
      key: 'brokerLabel'
    },
    {
      title: 'Broker Timezone',
      dataIndex: 'brokerTimezone',
      key: 'brokerTimezone'
    },
    {
      title: 'Profit Calculation',
      dataIndex: 'profitCalculationMethod',
      key: 'profitCalculationMethod'
    },
    {
      title: 'Logs Imported',
      dataIndex: 'numberLogFiles',
      key: 'numberLogFiles'
    },
    {
      title: 'Number of Trades',
      dataIndex: 'numberOfTrades',
      key: 'numberOfTrades'
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes'
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'clear',
      render: (e) => <div style={{ display: 'flex', gap: '1.75rem' }}>

          {e.executionsLogs?.length > 0 ? <Popconfirm
              title='Remove all trade logs?'
              description='This will delete all the trade imports in this account. Are you sure?'
              onConfirm={() => confirmDeleteTrades(e)}
              okText='Yes'
              cancelText='No'
              placement='right'
            >
              <Tooltip title={<><WarningOutlined /> Remove All Trade Logs</>} color='orange'>
                  <Button size='small' type='text' disabled={e.label === 'Live Trade' } icon={<ClearOutlined />}>Remove Trade Logs</Button>
              </Tooltip>
          </Popconfirm> :
          <Button
              size='small'
              onClick={() => redirectToAddTrade(e)}
              type='text'
              shape='circle'
              disabled={e.label === 'Live Trade' }
              icon={<span className='tt-plus' style={{ fontSize: 12 }} />}>Import Trade Logs</Button>}
          <Popconfirm
              title='Delete Account?'
              description='Deleting this account will erase all your trades. Are you sure?'
              onConfirm={() => confirmDeleteAccount(e)}
              okText='Yes'
              cancelText='No'
              placement='right'
            >
              <Tooltip title={<><WarningOutlined /> Delete Account Completely</>} color='#E57373'>
                  <Button size='small' type='text' disabled={e.label === 'Live Trade' } icon={<DeleteOutlined />}>Delete Account</Button>
              </Tooltip>
          </Popconfirm>
      </div>
    }
  ];

  const columnsExpanded = (record) => [
    {
      title: 'Trading Period',
      dataIndex: 'executions',
      key: 'executions',
      render: (executions) => `${dayjs(executions[0].date).format('MMM DD, YYYY HH:mm:ss z')} - ${dayjs(executions[executions.length - 1].date).format('MMM DD, YYYY HH:mm:ss z')}`
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadTime',
      key: 'uploadTime',
      render: (time) => dayjs(time).format('MMM DD, YYYY HH:mm:ss z')
    },
    {
      title: 'Commission Name',
      dataIndex: 'commissionProfileName',
      key: 'commissionProfileName',
      render: (name) => name || '-'
    },
    {
      title: 'Log File',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (fileName) => <Button disabled={record.label === 'Live Trade'} onClick={() => downloadFile(fileName)}>Download</Button>
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (e) => <>
          <Popconfirm
              title='Delete Log File?'
              description='Deleting this log file will erase some of your trades imported. Are you sure?'
              onConfirm={() => {

                confirmDeleteLog(e);

              }}
              okText='Yes'
              cancelText='No'
              placement='right'
        >
              <Tooltip title={<>Remove Single Log File <WarningOutlined /></>} color='#E57373' >
                  {/* TODO: Add loading indicator */}
                  <Button size='small' type='text' disabled={record.label === 'Live Trade'} icon={<DeleteOutlined />}>Remove Trade Log File</Button>
              </Tooltip>
          </Popconfirm>
      </>
    }
  ];

  return (
      <Space direction='vertical' align='start' style={{ width: '100%', marginBottom: '50px' }}>
          <Title level={4}>CREATE NEW ACCOUNT</Title>
          <Form
              name='account-form'
              form={form}
              layout='horizontal'
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              style={{ minWidth: 580 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              initialValues={{
                broker: 'das_trader',
                profitCalculationMethod: 'average',
                brokerTimezone: 'America/New_York'
              }}
      >
              <Form.Item
                  label='Account Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'A name for your account.'
                    }
                  ]}
        >
                  <Input />
              </Form.Item>

              <Form.Item
                  label='Broker'
                  name='broker'
                  form={form}
                  rules={[
                    {
                      required: true,
                      message: 'Select a broker'
                    }
                  ]}
        >
                  <Select
                      id='broker'
                      showSearch
            //   defaultValue="interactive_brokers"
                      style={{ width: '100%' }}
                      options={brokers || []}
                      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
              </Form.Item>

              <Form.Item
                  label='Broker Timezone'
                  name='brokerTimezone'
                  rules={[
                    {
                      required: true,
                      message: 'Select a timezone'
                    }
                  ]}
        >
                  <Space size={[24, 0]} direction='horizontal'>
                      <Text id='brokerTimezone'>America/New_York</Text>
                      {/* <Select
                          id='brokerTimezone'
                          showSearch
                          defaultValue={{
                            label: autoDetectedTimezone,
                            value: autoDetectedTimezone
                          }}
                          style={{ width: '100%' }}
                          options={(Intl.supportedValuesOf('timeZone') || []).map((timeZone) => ({
                            label: timeZone,
                            value: timeZone
                          }))}
                          onChange={onChangeTimezone} // REQUIRED to fix bug with search value not overwritten
                          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                        />

                      <Tag>Your Timezone: {timezoneValue}</Tag> */}
                  </Space>

              </Form.Item>

              <Form.Item
                  label='P&L Calculation Method'
                  name='profitCalculationMethod'
        // rules={[
        //   {
        //     required: true,
        //     message: 'Select a method'
        //   }
        // ]}
        >
                  {/* <Select
                      id='profitCalculationMethod'
                      style={{ width: '100%' }}
                      options={profitCalculationMethods}
                    /> */}
                  <Text id='profitCalculationMethod'>Weighted Average</Text>
              </Form.Item>
              <Form.Item
                  label='Notes'
                  name='notes'
        >
                  <TextArea
                      showCount
                      maxLength={100}
                      style={{
                        height: 100,
                        resize: 'none'
                      }}
                      placeholder='(Optional) notes for this account.'
          />
              </Form.Item>
              <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16
                  }}
        >
                  <Button type='primary' htmlType='submit'>
                      Create
                  </Button>
              </Form.Item>
          </Form>

          <Title level={5}>ACCOUNTS</Title>

          {allAccounts &&
          <Table
              columns={columns}
              dataSource={allAccounts}
              pagination={false}
              rowKey='key'
              expandable={{
                expandedRowRender: (record) => (
                    <>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Text type='secondary' italic><FileSearchOutlined /> Logs imported for this account</Text>
                        </Row>
                        <Table
                            columns={columnsExpanded(record)}
                            dataSource={record.executionsLogs?.map((r) => ({
                              ...r,
                              profitCalculationMethod: record.profitCalculationMethod
                            }))}
                            pagination={false}
                            bordered
                />
                    </>
                )
              }}
        />
      }
      </Space>
  );

}
