import React, { useState } from 'react';
import { Button, Input, message, Space, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';

import { CloseCircleFilled, EditOutlined, StarFilled } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import { useManagementStore, useUserState } from '../../store';
import axios from '../../utilities/axios';

const { Title } = Typography;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const PctIdCell = ({ userId, record, updateUser, company }) => {

  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleSave = () => {

    // Save the input value and update the state
    setEditing(false);
    // Perform save operation with the inputValue and record
    updateUser(record.id, { [company === 'Peak Capital Trading' ? 'pct_id' : 'ptt_id']: inputValue });

  };

  let content;
  if (editing) {

    content = (
        <>
            <Input
                defaultValue={userId}
                maxLength={15}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSave()}
        />
            <Button type='primary' onClick={handleSave}>
                Save
            </Button>
            <Tooltip title='Cancel' key={record.id}>
                <Button type='text' shape='circle' onClick={() => setEditing(false)} icon={<CloseCircleFilled />} />
            </Tooltip>

        </>
    );

  } else if (userId) {

    content = (
        <>
            <span>{userId}</span>
            <Tooltip title='Edit' key={record.id}>
                <Button type='text' shape='circle' onClick={() => setEditing(true)} icon={<span className='tt-edit'></span>} />
            </Tooltip>

        </>
    );

  } else {

    content = (
        <Tooltip title='Edit' key={record.id}>
            <Button type='text' shape='circle' onClick={() => setEditing(true)} icon={<EditOutlined />} />
        </Tooltip>
    );

  }

  return (
      <Space style={{ width: 250, justifyContent: 'end' }}>
          {content}
      </Space>
  );

};

export default function UserManagementPage() {

  const [allUsersList, setAllUsersList] = useState([]);
  const { getUser } = useUserState();
  const user = getUser();
  const { company, setAllUsers } = useManagementStore((state) => state);

  // const navigate = useNavigate();

  // useEffect(() => {

  //   if (company === 'Personal') {

  //     navigate('/settings/1');

  //   }

  // }, [company, navigate]);
  const fetchUsers = () => axios.get('crud/users', { headers: { token: user?.token } }).then(({ data }) => data);

  const { refetch } = useQuery(['users', company], () => fetchUsers().then(async (users) => {

    if (users.length > 0) {

      const usersFiltered = users.filter((u) => {

        if (company === 'Bear Bull Traders') {

          return u.ptt_id;

        }

        if (company === 'Peak Capital Trading') {

          return u.pct_id;

        }

        return false;

      });

      const sortedUsers = usersFiltered
        .sort((a, b) => {

          const aIncludes = a.pct_id?.includes('TRPCT') || a.ptt_id?.includes('TRPTT');
          const bIncludes = b.pct_id?.includes('TRPCT') || b.ptt_id?.includes('TRPTT');

          if (aIncludes && !bIncludes) return -1; // `a` goes before `b`
          if (!aIncludes && bIncludes) return 1; // `b` goes before `a`

          // If both include or both do not include, sort alphabetically
          return a.pct_id?.localeCompare(b.pct_id) || a.ptt_id?.localeCompare(b.ptt_id);

        });

      setAllUsersList(sortedUsers);

      const userSelectionFormat = users?.map((u) => {

        if (u.role !== 'admin') {

          return {
            value: u.id,
            label: u.pct_id && u.pct_id !== '' ? u.pct_id : u.name,
            token: u.token
          };

        }

        return null;

      }).filter(Boolean) || [];

      // // Adding my own account to the top of the list
      // const currentAdmin = users.find((x) => x.id === user.id);
      // userSelectionFormat.unshift({
      //   value: user?.id,
      //   label: `${currentAdmin && currentAdmin.pct_id && currentAdmin.pct_id !== '' ? currentAdmin.pct_id : user?.name} (Me)`,
      //   token: user?.token
      // });

      setAllUsers(userSelectionFormat);

    } else {

      setAllUsersList([]);

    }

    return users;

  }), { enabled: user.token !== undefined && user.role === 'admin' });

  const updateUser = (userId, userData) => axios.put(`crud/users/${userId}`, {
    userData
  }, { headers: { token: user?.token } })
    .then(refetch)
    .catch(() => {

      message.error('Something went wrong! Please report to the development team.');

    });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'User ID',
      dataIndex: company === 'Peak Capital Trading' ? 'pct_id' : 'ptt_id',
      key: company === 'Peak Capital Trading' ? 'pct_id' : 'ptt_id',
      render: (item, record) => <PctIdCell userId={item} record={record} updateUser={updateUser} company={company} />
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (item) => {

        if (!item) return '';

        if (item === 'admin') {

          return <span>{item.toUpperCase()} <StarFilled /></span>;

        }

        return <span>{item.charAt(0).toUpperCase() + item.slice(1)}</span>;

      }
    }
  ];

  return (
      <Space direction='vertical' align='start' style={{ width: '100%' }}>
          <Title level={4}>{company || 'List of'} Users</Title>

          {allUsersList &&
          <Table
              columns={columns}
              dataSource={allUsersList}
              pagination={false}
              rowKey='key'
              style={{ width: 900 }}
                    />
            }
      </Space>
  );

}

PctIdCell.propTypes = {
  userId: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired
};
