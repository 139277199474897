/* eslint-disable no-nested-ternary */
import { React } from 'react';
import { Avatar, Col, Dropdown, Select, Space, Typography } from 'antd';
import { useNavigate } from 'react-router';

import PersonImg from '../../../../resources/images/person.svg';
import { useManagementStore, useUserState } from '../../../../store';
import { getCookie } from '../../../../utilities/cookie';
// import AIDrawer from '../ai-drawer';
// import ImportDrawer from '../import-drawer';
import Logo from '../logo';

import './styles.scss';

const { Text } = Typography;

export default function Header() {

  // const [AIDrawerOpen, setAIDrawerOpen] = useState(false);
  const { resetUser, getUser } = useUserState();
  const { company, setCompany } = useManagementStore();
  const { viewAs, setViewAs } = useManagementStore((state) => state);
  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);
  // const { allAccounts } = useAccountStore((state) => state);
  const user = getUser();
  // const { importDrawerOpen, setImportDrawerOpen } = useImportDrawerStore();

  const navigate = useNavigate();
  const userFromCookie = JSON.parse(getCookie('user'));

  // const onImportClose = () => setImportDrawerOpen(false);

  //   const showAIDrawer = () => setAIDrawerOpen(true);
  // const onAIClose = () => setAIDrawerOpen(false);

  const handleLogout = () => {

    // logout();
    resetUser();
    navigate(0);
    // window.location.replace('/');

  };

  const handleChange = (e) => {

    if (isViewAsActive) {

      setViewAs({ token: user?.token, id: user?.id }, { token: user?.token, id: user?.id });

    }

    setCompany(e);

  };

  const basicMeasureOptions = [
    {
      key: 'Settings',
      label: 'Settings',
      icon: <span className='tt-person' style={{ fontSize: 16 }} />,
      onClick: () => navigate('settings/1')
    },
    {
      key: 'Logout',
      label: <span style={{ color: '#e57373' }}>Logout</span>,
      icon: <span className='tt-logout' style={{ fontSize: 16, color: '#e57373' }} />,
      onClick: () => handleLogout()
    }
  ];

  const companyOptions = [
    {
      value: 'Personal',
      label: 'Personal'
    },
    {
      value: 'Bear Bull Traders',
      label: 'Bear Bull Traders'
      // onClick: () => navigate('settings/1')
    },
    {
      value: 'Peak Capital Trading',
      label: 'Peak Capital Trading'
      // onClick: () => handleLogout()
    }
  ];
  const renderContent = () => (
      <>
          <Col span={8}>
              <div className='align-start'>
                  <div className='logo-and-company'>
                      <Logo />
                      {user.role === 'admin' && (
                          <Select
                              // mode='single'
                              onChange={handleChange}
                              options={companyOptions}
                              value={company}
                              showSearch={false}
                              dropdownRender={(menu) => (
                                  <div className='custom-drop'>
                                      {menu}
                                  </div>
                              )}
                              dropdownStyle={{ width: 190, backgroundColor: '#E0E3EB', padding: 0 }}
                        //   tagRender={(tag) => (
                        //       <span className={tag.value && filter.accounts.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                        //           {tag.label}
                        //           {tag.value && filter.accounts.indexOf(tag.value) < filter.accounts.length - 1 ? ',\u00A0' : ''}
                        //       </span>
                        //   )}
        />
                      //   <Dropdown menu={{ items: companyOptions, selectable: true, defaultSelectedKeys: 'Personal', onSelect: setCompany }} trigger={['click']}>
                      //       <Button className='button-company'>{company} <DownOutlined /></Button>
                      //   </Dropdown>)
                      )}
                  </div>
              </div>
          </Col>
          <Col span={8} offset={8}>
              <Space size={24} className='align-end'>
                  <Dropdown menu={{ items: basicMeasureOptions, selectable: false }}>
                      <div className='user-dropdown-button' onClick={(e) => e.preventDefault()}>
                          <Avatar className='user-avatar' size={32} src={userFromCookie?.avatar || PersonImg} />
                          <Text strong className='user-name-text' style={{ padding: 5 }}>
                              Hi, {userFromCookie?.name || ''}
                          </Text>
                          <span className={`down-icon tt-Chevron_down ${userFromCookie?.darkMode ? 'dark' : ''}`} />
                      </div>
                  </Dropdown>
              </Space>
          </Col>
      </>
  );

  return (
      <>
          <div className='header-container'>
              {renderContent()}
              {/* <ImportDrawer open={importDrawerOpen} onClose={onImportClose} />
              <AIDrawer open={AIDrawerOpen} onClose={onAIClose} /> */}
          </div>
      </>
  );

}
