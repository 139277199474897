import { useLocation } from 'react-router-dom';

const usePageTitle = () => {

  const { pathname } = useLocation();

  switch (pathname) {

    case '/':
      return 'Dashboard';
    case '/add-trade':
      return 'Upload Trade';
    case '/calendar':
      return 'Calendar';
    case '/settings':
      return 'Settings';
    case '/summary':
      return 'Statistics';
    case '/trades':
      return 'Trades';
    case '/compare':
      return 'Compare';
    case '/daily-trade-notes':
      return 'Daily Notes';
    default:
      return 'Default Title'; // Fallback title

  }

};

export default usePageTitle;
